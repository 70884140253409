import { Link } from '@remix-run/react';
import { Button } from '@web/components/ui/button';
import { streamerSignInRoute } from '@web/routes/auth';
import { appRoute } from '@web/routes/other';
import { cn } from '@web/utilities/styles';

import { CompanyFeature } from './CompanyFeature';
import LinesBg from './lines_bg_company_block.webp';
import P1Face from './P1-face.webp';
import P2Bridge from './P2-bridge.webp';
import P3Puzzle from './P3-puzzle.webp';
import P4Forest from './P4-forest.webp';
import XBlockLeft from './x-block-left.webp';
import XBlockRight from './x-block-right.webp';

type CompanySectionProps = {
  isAuthenticated: boolean;
};
export const CompanySection = ({ isAuthenticated }: CompanySectionProps) => {
  return (
    <section
      id="company"
      className="relative bg-slate-100 pt-24 sm:pt-[152px] xl:pt-48"
    >
      <div
        className="absolute inset-y-0 left-1/2 size-full -translate-x-1/2"
        style={{
          backgroundImage: `url(${LinesBg})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center top',
          backgroundSize: 'contain',
          zIndex: 0
        }}
      />
      <div className="relative z-10 xl:flex xl:flex-col xl:items-center">
        <h2 className="text-center font-inter text-3xl font-semibold uppercase text-slate-900 sm:text-40">
          why onezee?
        </h2>
        <div className="mx-auto mt-6 h-[5px] w-28 bg-slate-900 sm:mt-24" />
        <div className="mt-16 flex flex-col items-center space-y-6 sm:mt-24 sm:space-y-12 xl:w-full xl:max-w-[1520px] xl:flex-row xl:flex-wrap xl:justify-center xl:gap-6 xl:space-y-0">
          <CompanyFeature
            title="It's for ALL Streamers"
            description="Whether you have 10 viewers or 10 thousand, you can start earning on your stream with Onezee. We believe every streamer deserves the opportunity to monetise their content."
            imageSrc={P1Face}
            index={0}
          />
          <CompanyFeature
            title="No Middlemen"
            description="Deal directly with brands through our platform, cutting out intermediaries and maximising your earnings."
            imageSrc={P2Bridge}
            index={1}
          />
          <CompanyFeature
            title="Tailored Matches"
            description="Our AI-powered platform ensures you're matched with brands that align with your content and audience, creating authentic partnerships."
            imageSrc={P3Puzzle}
            index={2}
          />
          <CompanyFeature
            title="Growth Support"
            description="As your channel grows, Onezee grows with you, continually matching you with opportunities that fit your evolving audience and content."
            imageSrc={P4Forest}
            index={3}
          />
        </div>
        <div className="my-24 flex justify-center sm:mb-[152px] xl:my-[152px]">
          <Button
            variant="landing"
            size="default"
            className={cn(
              'h-[50px] w-full sm:w-[199px] text-sm font-semibold rounded-lg mx-4'
            )}
            asChild
          >
            <Link
              to={isAuthenticated ? appRoute() : streamerSignInRoute()}
              prefetch="intent"
            >
              {isAuthenticated ? 'Go to app' : 'Join Now'}
            </Link>
          </Button>
        </div>
      </div>
      <img
        src={XBlockLeft}
        alt="Decorative block element on the left"
        className="
          absolute bottom-[-75px]
          left-0 h-[53px]
          sm:bottom-[-50px] sm:h-[87px]
          xl:bottom-[85px]
        "
      />
      <img
        src={XBlockRight}
        alt="Decorative block element on the right"
        className="
          absolute right-0 top-[18px] z-10 h-[85px]
          sm:top-[55px] sm:h-[193px]
          xl:top-[93px] xl:h-[253px]
        "
      />
    </section>
  );
};
